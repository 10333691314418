import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useLocation } from 'react-router-dom'; 

// Core Components
import MFCHeader from "components/mfc-training/mfc-header/MFCHeader.js";
import Stanley from "components/mfc-training/mfc-testimonials/Stanley.js";
import Coaches from 'components/mfc-training/mfc-teams/Coaches.js';
import Coaches2 from 'components/mfc-training/mfc-teams/Coaches2.js';
import Feature4 from 'components/mfc-training/mfc-features/Feature4.js';
import Feature8 from 'components/mfc-training/mfc-features/Feature8.js';
import Feature2 from 'components/mfc-training/mfc-features/Feature2.js';
import BackgroudCards from 'components/mfc-training/mfc-backgroundCards/BackgroundCards.js';
import WorkoutLevels from "components/mfc-training/mfc-workout-levels/WorkoutLevels.js";
import Chat from "components/mfc-training/mfc-chat/Chat.js";
import Pricing5 from "components/mfc-training/mfc-pricing/Pricing5.js";
import FAQ from "components/mfc-training/mfc-accordion/FAQ.js";
import MfcNavbar from "components/mfc-training/mfc-navbar/MfcNavbar.js";
import MfcFooter from "components/mfc-training/mfc-footer/MfcFooter.js";

function MfcTraining() {
  // what is it doing?
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });

    const location = useLocation();
  
    React.useEffect(() => {
      const { hash } = window.location;
      if (hash !== '') {
        // Push onto callback queue so it runs after the DOM is updated,
        // this is required when navigating from a different page so that
        // the element is rendered on the page before trying to getElementById.
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) element.scrollIntoView();
        }, 0);
      }
    }, [location]);
  
  return (
    <>
      <MfcNavbar type="transparent" />
      <div className="wrapper">
        <MFCHeader />
        <div className="main ">
        <section className='section'>
          <Coaches /> 
          <BackgroudCards />  
          </section>
          <section className='section' id="section-about-us">
            <Coaches2 />
            </section>
            <section className='section'>
          <Feature4 />
          <Stanley />
          </section>
          <section className="section" id="coaching">
          <Feature2 />
          </section>  
          <section className='section'>
          <Feature8 />
          </section>
          <section className="section">
            <Chat />
            </section>
          <section className="section" id="section-workoutlevels">
            <WorkoutLevels />
            <Pricing5 />
          </section> 
          <section className="section">
          <FAQ />
          </section>
        </div>
        <MfcFooter />
      </div>
    </>
  );
}

export default MfcTraining;
