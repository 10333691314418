import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle } from "reactstrap";

// Core Components
function FullBackGroundCardS3() {
  return (
    <>
      <Card className="card-blog card-background" data-animation="zooming">
        <div
          className="full-background"
          style={{
            backgroundImage:
              "url(" + require("assets/img/faces/104571040.JPG") + ")",
          }}
        ></div>
        <a href="#pablo" onClick={(e) => e.preventDefault()}>
          <CardBody>
            <div className="content-bottom">
            <CardTitle tag="h5">Save time</CardTitle>
              <h6 className="card-category text-white opacity-8">
              We do the heavy lifting for you. So you can trust you’re getting 
              the highest quality programs without having to take time 
              away from family, goals, or career to prepare them yourself.
              
              
              </h6>
            </div>
          </CardBody>
        </a>
      </Card>
    </>
  );
}

export default FullBackGroundCardS3;
