import React from "react";
// reactstrap components
import { Card, Container, Badge, Row, Col } from 'reactstrap';
// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import OneonOneChat from "components/chat/OneonOneChat.js";

function Chat() {
  return (
    <>
    <Container>
      <Row>
      <Col lg="12" md="10"> 
      <div>
        <h3 className="h4 text-success font-weight-bold mb-5 mt-5">1:1 Support via Text Message</h3>
        <p>Support when you need it.</p>
        <OneonOneChat />
      </div>
      
      </Col>
      </Row>
    </Container>
      
   
      
    </>
  );
}

export default Chat;
