import React from "react";

// reactstrap components
import { Button, Card, CardBody, CardFooter, CardTitle } from "reactstrap";

// Core Components

function RosasProfileCard() {
  return (
    <>
      <Card className="card-profile" data-image="img-raised">
        <div className="card-header-image">
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img"
              src={require("assets/img/faces/Rosas.jpg")}
            ></img>
          </a>
          <CardTitle className="text-white" tag="div">
            Mike Rosas
          </CardTitle>
        </div>
        <CardBody>
          <h6 className="card-category text-info text-left">
            Coaching Credentials
          </h6>
          
          {/* <h5 className="card-category">Coaching Credentials:</h5> */}
              <ul className="card-list-elements">
                <li>NSCA-CSCS</li>
                <li>NASM-PES/IFS/CES</li>
                <li>CHEK Level 2, Golf Biomechanic</li>
              </ul>
          
        </CardBody>
        <CardFooter className="text-center">
          <Button
            className="btn-icon-only btn-simple btn-default"
            color="instagram"
            type="button"
          >
            <span className="btn-inner--icon">
            <i className="fas fa-dumbbell"></i>
            </span>
          </Button>
          <Button
            className="btn-icon-only btn-simple"
            color="twitter"
            type="button"
          >
            <span className="btn-inner--icon">
            <i className="fas fa-clipboard-list"></i>
            </span>
          </Button>
          <Button
            className="btn-icon-only btn-simple"
            color="dribbble"
            type="button"
          >
            <span className="btn-inner--icon">
            <i className="far fa-check-circle"></i>
            </span>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}

export default RosasProfileCard;
