import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

function WorkoutLevels() {
  return (
    <>
      <section className="blogs-2">
        <Container fluid>
          <Row className="mb-md-5">
            <Col className="mx-auto" md="7">
              <h3 className="display-3 text-center">Home &amp; Gym Workouts</h3>
              <p className="lead text-center"> 
                Professional programs meant to maximize your results, all while fitting into your lifestyle. 
                Pick the one that best fits you. Not sure which one? No problem your coach can help guide you.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/faces/507817844936.JPG") + ")",
                  }}
                ></div>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <CardBody>
                    <div className="content-bottom">
                      <h4 className="card-category text-white">
                        <span><i class="fas fa-at"></i></span>HOME
                      </h4>
                      <h5 className="card-category text-white opacity-8">3 Days a week</h5>
                      <h5 className="card-category text-white opacity-8">Bands &amp; Light Dumbbells</h5>
                      <p className="text-white card-category">Mix of Total Body and steady state cardio.</p>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/faces/runningstairs.jpg") + ")",
                  }}
                ></div>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <CardBody>
                    <div className="content-bottom">
                      <h4 className="card-category text-white opacity-8">
                        LIFESTYLE 
                      </h4>
                      <h5 className="card-category text-white opacity-8">3 Days a week</h5>
                      <h5 className="card-category text-white opacity-8">2 Optional Conditioning Days</h5>
                      <p className="text-white">Mix of Total Body and Steady State Cardio.</p>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/faces/battleropes.jpg") + ")",
                  }}
                ></div>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <CardBody>
                    <div className="content-bottom">
                      <h4 className="card-category text-white opacity-8">
                        LIFESTYLE &amp; HIIT
                      </h4>
                      <h5 className="card-category text-white opacity-8">3 Days a week</h5>
                      <h5 className="card-category text-white opacity-8">2 Optional HIIT Conditioning Days</h5>
                      <p className="text-white">Mix of Total Body and HIIT workouts.</p>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/faces/lifestyleplus.jpg") + ")",
                  }}
                ></div>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <CardBody>
                    <div className="content-bottom">
                      <h4 className="card-category text-white opacity-8">
                        LIFESTYLE <span><i class="fas fa-plus"></i></span>
                      </h4>
                      <h5 className="card-category text-white opacity-8">5 Days a week</h5>
                      <h5 className="card-category text-white opacity-8">1 Optional Recovery Day</h5>
                      <p className="text-white">Mix of Strength, Hypertrophy, 
                      Bodybuilding, Total Body, Upper &amp; Lower Body Splits.</p>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default WorkoutLevels;
