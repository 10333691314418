import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

import PricingCard3 from 'components/mfc-training/mfc-pricing/PricingCard3.js';
// Core Components

function Pricing5() {
  const [activeTab, setActiveTab] = React.useState("tab1");
  return (
    <>
      <section
        className="pricing-5"
        id="pricing-6"
        style={{
          backgroundImage:
            "url(" + require("assets/img/ill/pattern_pricing1.svg") + ")",
        }}
      >
        <Container className="pt-5" >
          <Row>
            <Col className="d-flex justify-content-center flex-column" md="4">
              <h3 className="display-3 text-uppercase mt-3">
                Train with MFC
              </h3>
              <h4>Your personal fitness coach</h4>
              <h6 className="text-danger">**Limited spots available</h6>
              <div className="buttons nav-pills-primary my-4">
                  <Button
                    color="primary"
                    href="/#section-workoutlevels"
                  >
                    Apply Now
                  </Button>
                </div>
              <p className="lead mt-0">
                My team and I are here to help you succeed long term. 
                Try MFC risk-free for a month. If it's not for you, 
                we'll give you a full refund.
              </p>
            </Col>
            <Col className="ml-auto mr-auto"  lg="8" md="8">
                  <Row>
                    <Col className="pt-5" md="6">
                    <Card className="card-pricing card-background">
                      <CardBody>
                        <CardTitle className="text-primary text-left ml-2 text-uppercase" tag="h2">
                          On Your Own
                        </CardTitle>
                        <ul>
                          <li className="text-left">
                            <strong>Without</strong> a Coach
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="danger">
                              <i className="fas fa-times text-white"></i>
                            </Badge>{" "}
                            Spend Extra Time Planning
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="danger">
                              <i className="fas fa-times text-white"></i>
                            </Badge>{" "}
                            Susceptible To Training Gaps
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="danger">
                              <i className="fas fa-times text-white"></i>
                            </Badge>{" "}
                            Not A Fitness Expert 
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="danger">
                              <i className="fas fa-times text-white"></i>
                            </Badge>{" "}
                            Proper Program Design
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="danger">
                              <i className="fas fa-times text-white"></i>
                            </Badge>{" "}
                            Not Tracking Progress
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="danger">
                              <i className="fas fa-times text-white"></i>
                            </Badge>{" "}
                            No Accountability
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="pt-5" md="6">
                    <Card className="card-pricing card-background">
                      <CardBody>
                        <CardTitle className="text-primary text-left ml-2 text-uppercase" tag="h2">
                          Executive Plan
                        </CardTitle>
                        <ul>
                          <li className="text-left">
                            Up to <strong>2</strong> Users
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="success">
                              <i className="ni ni-check-bold text-white"></i>
                            </Badge>{" "}
                            1:1 Remote Support Via Text
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="success">
                              <i className="ni ni-check-bold text-white"></i>
                            </Badge>{" "}
                            New Weekly Workouts
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="success">
                              <i className="ni ni-check-bold text-white"></i>
                            </Badge>{" "}
                            Daily Checkoffs
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="success">
                              <i className="ni ni-check-bold text-white"></i>
                            </Badge>{" "}
                            Daily Reminders
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="success">
                              <i className="ni ni-check-bold text-white"></i>
                            </Badge>{" "}
                            Weekly/Bi-Weekly Check-ins
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="success">
                              <i className="ni ni-check-bold text-white"></i>
                            </Badge>{" "}
                            Track Progress
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="success">
                              <i className="ni ni-check-bold text-white"></i>
                            </Badge>{" "}
                            Accountability
                          </li>
                          <li className="text-left">
                            <Badge className="badge-circle" color="success">
                              <i className="ni ni-check-bold text-white"></i>
                            </Badge>{" "}
                            Join with a friend
                          </li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="primary"
                          href="#section-workoutlevels"
                        >
                          Apply Now
                      </Button>
                      </CardBody>
                    </Card>              
                    </Col>
                  </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Pricing5;
