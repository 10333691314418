import React from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// Core Components

function Feature2() {
  return (
    <>
      <div className="section features-2">
        <Container>
          <Row className="align-items-center">
            <Col className="mr-auto text-left" lg="4" md="8">
              <div className="pr-md-5">
                <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5">
                  <i className="ni ni-favourite-28"></i>
                </div>
                <h3>Types of training</h3>
                <p>
                Traditional training program designs often result in an 
                incomplete training program. Your programming will be built 
                on a foundation of principles that progressively and systematically 
                allow you to achieve optimal health levels.  
                </p>
                <ul className="list-unstyled mt-5">
                <h3>Physical Benefits</h3>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="primary">
                        <i className="fas fa-weight"></i>
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0">Decrease body fat</h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="primary">
                        <i className="far fa-smile"></i>
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0">Increase lean muscle mass</h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="primary">
                        <i className="fas fa-chart-line"></i>
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0">Increase strength</h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="pl-md-0" lg="8" md="12">
              <Row>
                <Col lg="4" md="4">
                  <div className="info text-left bg-info shadow">
                    <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="ni ni-satisfied text-info"></i>
                    </div>
                    <h5 className="info-title text-white">Flexability</h5>
                    <p className="description">
                    Nearly everyone is plagued by postural imbalances largely as the result of 
                    sedentary lifestyles, and technology. Your training 
                    will help correct muscle imbalances, and improve range of motion.
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="info text-left bg-danger info-raised shadow">
                    <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="fas fa-dumbbell text-danger"></i>
                    </div>
                    <h5 className="info-title text-white">Resistance</h5>
                    <p className="description">
                      There are multiple stages of Resistance training. 
                      Avoiding pitfalls requires a systematic, progressive training 
                      program that is cycled through different stages based on science and proven application.
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="info text-left bg-default shadow">
                    <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="fas fa-heart text-default"></i>
                    </div>
                    <h5 className="info-title text-white">Cardiorespiratory</h5>
                    <p className="description">
                    Research has confirmed that an individual’s cardiorespiratory fitness 
                    level is one of the strongest predictors of morbidity and mortality.
                    Cardiovascular training is essential for your health.  
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="4" md="4">
                  <div className="info text-left bg-primary shadow">
                    <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="fas fa-walking text-primary"></i>
                    </div>
                    <h5 className="info-title text-white">Core</h5>
                    <p className="description">
                      Your core helps stabilize your body. Few people have developed their
                      stabilization muslces required for optimal efficiency for strength, power, 
                      and endurance exercises. 
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="info text-left bg-warning info-raised shadow">
                    <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="fas fa-balance-scale text-warning"></i>
                    </div>
                    <h5 className="info-title text-white">Balance</h5>
                    <p className="description">
                      Balance is key to all functional movements and optimal muscle output, and may help avoid injuries.
                      Muscles with imbalances can cause joint dysfunction and pain. 
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="info text-left bg-success shadow">
                    <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                      <i className="fas fa-running text-success"></i>
                    </div>
                    <h5 className="info-title text-white">Plyometric</h5>
                    <p className="description">
                    Success in everyday activities and sport depends on the 
                    speed at which muscular force is generated. 
                    Plyometric training can provide better functional strength
                    of the muscles, tendons, and ligaments to meet the demands of everyday activities and sport. 
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature2;
