/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Core Components

function MfcFooter() {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="row-grid align-items-center mb-5">
            <Col lg="6">
            <div className="column">
                  <img
                    alt="..."
                    className="logo"
                    src={require("assets/img/brand/mfcLogowhite200.png")}
                  ></img>
                </div>
              <h6 className="mb-0 font-weight-light text-white">
              It’s never about how you start your health plan. 
              It’s about how you continue. 
              </h6>
            </Col>
            <Col className="text-lg-center btn-wrapper" lg="6">
              <button type="button" class="btn-icon-only rounded-circle btn btn-instagram">
                <span class="btn-inner--icon">
                  <i class="fab fa-instagram"></i></span>
              </button>
              <button type="button" class="btn-icon-only rounded-circle btn btn-youtube">
                <span class="btn-inner--icon">
                  <i class="fab fa-youtube"></i></span>
              </button>
              <button type="button" class="btn-icon-only rounded-circle btn btn-vimeo">
                <span class="btn-inner--icon">
                  <i class="fab fa-vimeo"></i></span>
              </button>
            </Col>
          </Row>
          <hr></hr>
          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright">
                © 2021{" "}
                <a
                  href="/"
                >
                  My Fitness Coach LLC
                </a>
                .
              </div>
            </Col>
            <Col md="6">
              <Nav className="nav-footer justify-content-end">
                <NavItem>
                  <NavLink
                    href="/"
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="/#section-about-us"
                  >
                    About
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="/#section-workoutlevels"
                  >
                    Apply Now
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    href="https://coaches.myfitnesscoach.io/"
                  >
                    MFC For Coaches
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default MfcFooter;
