import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components
import DemoNavbar from "components/navbars/DemoNavbar.js";
import DemoFooter from "components/footers/DemoFooter.js";
import HeaderBlogPosts from "components/headers/HeaderBlogPosts.js";
import BlogCard10 from "components/cards/BlogCard10.js";
import BlogCard11 from "components/cards/BlogCard11.js";
import BlogCard12 from "components/cards/BlogCard12.js";
import Blogs3 from "components/blogs/Blogs3.js";
import Blogs4 from "components/blogs/Blogs4.js";
import Team2 from "components/teams/Team2.js";
import PreFooter2 from "components/pre-footers/PreFooter2.js";
import PreFooter5 from "components/pre-footers/PreFooter5.js";
import Coaches from 'components/teams/Coaches.js';
import ProfilePage from 'views/examples/ProfilePage.js';
import ProfileCard7 from "components/cards/ProfileCard7.js";
import Coaches2 from 'components/teams/Coaches2.js';
import Feature4 from 'components/features/Feature4.js';
import Feature8 from 'components/features/Feature8.js';
import Feature2 from 'components/features/Feature2.js';
import Projects3 from 'components/projects/Projects3';
import RosasProfileCard from "components/cards/RosasProfileCard.js";
import BackgroudCards from 'components/landingPage/BackgroudCards.js';
import TeamLevels from 'components/teams/TeamLevels.js';
import WorkoutLevels from "components/blogs/WorkoutLevels.js";
import ProgramLevels from 'components/teams/ProgramLevels.js';
import ChatComponent from "components/chat/Chat.js";
import Chat from "components/index-page/Chat.js";
import OneonOneChat from "components/chat/OneonOneChat.js";
import Footer1 from "components/footers/Footer1";
import Footer2 from "components/footers/Footer2";
import Footer3 from "components/footers/Footer3";
import Footer4 from "components/footers/Footer4";
import Footer5 from "components/footers/Footer5";
import PreFooter4 from "components/pre-footers/PreFooter4";
import Footer6 from "components/footers/Footer6";

function BlogPosts() {
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <DemoNavbar type="transparent" />
      <div className="wrapper">
        <HeaderBlogPosts />
        <div className="main ">
        <section className='section'>
          <Coaches /> 
          <BackgroudCards />  
          </section>
          <section className='section'>
            <Coaches2 />
            </section>
            <section className='section'>
          <Feature4 />
          <Blogs3 />
          </section>
          {/* <section className='section'>
          <Blogs3 />
          </section>   */}
          <section className="section">
          <Feature2 />
          </section>  
          <section className='section'>
          <Feature8 />
          </section>
          <section className="section">
            <Chat />
            </section>
          <section className="section">
            <WorkoutLevels />
            {/* <ProgramLevels />
            <TeamLevels /> */}
          </section> 
          {/* <section className="section">
          <PreFooter5 />
          </section> */}
         
        </div>
        <Footer4 />
      </div>
    </>
  );
}

export default BlogPosts;
