import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components
// Blog Cards
import BlogCard1 from "components/cards/BlogCard1.js";
import BlogCard2 from "components/cards/BlogCard2.js";
import BlogCard3 from "components/cards/BlogCard3.js";
import BlogCard4 from "components/cards/BlogCard4.js";
import BlogCard5 from "components/cards/BlogCard5.js";
import BlogCard6 from "components/cards/BlogCard6.js";
import BlogCard7 from "components/cards/BlogCard7.js";
import BlogCard8 from "components/cards/BlogCard8.js";
import BlogCard9 from "components/cards/BlogCard9.js";

// Full Background Cards
import FullBackGroundCardS1 from "components/cards/FullBackGroundCardS1.js";
import FullBackGroundCardS2 from "components/cards/FullBackGroundCardS2.js";
import FullBackGroundCardS3 from "components/cards/FullBackGroundCardS3.js";


function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  return (
    <>
      <div className="section-cards">
        <Container>
        <Col className='text-center mb-5' md='12'>

        <h3 className='display-3 text-uppercase'>Join Our Executive Program</h3>
        </Col>
          <Row>
            <Col lg="4" md="6">
              <FullBackGroundCardS1 />
            </Col>
            <Col lg="4" md="6">
              <FullBackGroundCardS2 />
            </Col>
            <Col lg="4" md="6">
              <FullBackGroundCardS3 />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Index;
