import React from 'react';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Container,
  Row,
  Col,
} from 'reactstrap';

// Core Components

function Coaches() {
  return (
    <>
      <div className='coaches'>
        <Container>
          <Row>
            <Col className='mx-auto text-center mb-5' md='8'>
              <h3 className='display-3 text-uppercase'>
              Succeed with a Coach <br></br>&amp; proven System.
              </h3>
              <h4 className='lead'>
              Health is never owned, it’s rented. And rent is due <b>EVERYDAY.</b> To be 
              a top performer, you need to be <b>physically at your best.</b> Your physical health is 
                the center of your existence! If it goes, then it all 
                goes with it! No matter what other priorities you have, <b>nothing is 
                  more important</b> than your health!
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Coaches;
