import React from "react";

// reactstrap components
import { Button, Row, Col } from "reactstrap";

// Core Components

function HeaderBlogPosts() {
  return (
    <>
      <div className="page-header page-header-small header-filter">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/faces/mfccouplerun.jpg") + ")",
          }}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="mx-auto text-center" md="6">
              <h1 className="title text-white text-uppercase header-title">
              Stop Chasing The 30 Day Fix. It's Time To Play The Long Game.
              </h1>
              <p className="header-top-spacing"><b>1:1 Remote Fitness Coaching</b></p>

              <div className="buttons header-top-spacing">
                  <Button
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Get Started
                  </Button>
                </div>
                
                  <p className="header-top-spacing">Just $5 a day</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default HeaderBlogPosts;
