import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  Input,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

function Header5() {
  return (
    <>
      <header className="header-5">
       
        <div className="page-header">
          <Container>
            <Row className="align-items-center text-left">
            <Col className="pl-0" lg="7" xs="12">
                <img
                  alt="..."
                  className=""
                  src={require("assets/img/faces/Rosas.jpg")}
                  width="100%"
                ></img>
              </Col>
              <Col lg="5" xs="12">
                <h1 className="display-3">
                  World Class
                  <span className="text-primary">Coaches.</span>
                </h1>
                <p className="lead pb-4">
                  We aim high at being focused on building relationships with
                  our clients and community. Using our creative gifts drives
                  this foundation.
                </p>
                
                
              </Col>
              
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
}

export default Header5;
