import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components
import WorkoutLevels from "components/mfc-training/mfc-workout-levels/WorkoutLevels.js";
import Pricing5 from "components/mfc-training/mfc-pricing/Pricing5.js";
import FAQ from "components/mfc-training/mfc-accordion/FAQ.js";
import MfcNavbar from "components/mfc-training/mfc-navbar/MfcNavbar.js";
import MfcFooter from "components/mfc-training/mfc-footer/MfcFooter.js";

function MfcPrograms() {
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <MfcNavbar type="transparent" />
      <div className="wrapper">
          <section className="section" id="section-workoutlevels">
            <WorkoutLevels />
            <FAQ />
          </section> 
        </div>
        <MfcFooter />
    </>
  );
}

export default MfcPrograms;
