import React from 'react';

// reactstrap components
import { Card, CardImg, Container, Row, Col } from 'reactstrap';

// Core Components

function Feature4() {
  return (
    <>
      <div className='section features-4'>
        <Container>
          <Row>
            <Col className='text-center mx-auto' md='8'>
              <h3 className='display-3'>Our Approach</h3>
              <p className='lead'>
                To get real results you need a coach (with the education &amp; experience) and follow a program that
                helps you be consistent each month. No matter if your goal is to
                lose weight, gain lean muscle, or improve mobility. MFC Training
                brings you the best fitness programs right to the palm of your
                hand.
              </p>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col className='mr-auto text-left mt-4' lg='4'>
              <Card className='bg-default shadow border-0'>
                <CardImg
                  alt='...'
                  src={require('assets/img/faces/highfivemfc.jpg')}
                  top
                ></CardImg>
                <blockquote className='card-blockquote'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='svg-bg'
                    preserveAspectRatio='none'
                    viewBox='0 0 583 95'
                  >
                    <polygon
                      className='fill-default'
                      points='0,52 583,95 0,95'
                    ></polygon>
                    <polygon
                      className='fill-default'
                      opacity='.2'
                      points='0,42 583,95 683,0 0,95'
                    ></polygon>
                  </svg>
                  <h4 className='display-4 text-white'>You Need A System</h4>
                  <p className='lead text-italic text-white'>
                    Willpower and discipline fail overtime. That's why you need
                    a system to help keep you consistent.
                  </p>
                </blockquote>
              </Card>
            </Col>
            <Col className='p-sm-0' lg='7'>
              <Row>
                <Col md='6'>
                  <div className='info info-hover-warning'>
                    <div className='icon icon-shape bg-warning shadow rounded-circle text-primary'>
                      <i className='fas fa-clipboard-list text-white'></i>
                    </div>
                    <h5 className='info-title'>1:1 Accountablity</h5>
                    <p className='description opacity-8'>
                      You'll get daily reminders, weekly or bi-weekly check-ins. Plus 1:1 Remote support 
                      to ensure you stay on track to reach your goals. 
                      
                    </p>
                  </div>
                </Col>
                <Col md='6'>
                  <div className='info info-hover-info'>
                    <div className='icon icon-shape bg-info shadow rounded-circle text-primary'>
                      <i className='ni ni-calendar-grid-58 text-white'></i>
                    </div>
                    <h5 className='info-title'>Automate Your Workout Plan</h5>
                    <p className='description opacity-8'>
                      By automating your workouts it will give 
                      you more time to focus on your family, 
                      friends, and career. The time you save each week will more than pay for itself.

                    </p>
                  </div>
                </Col>
              </Row>
              <Row className='mt-lg-4'>
                <Col md='6'>
                  <div className='info info-hover-danger'>
                    <div className='icon icon-shape bg-danger shadow rounded-circle text-primary'>
                      <i className='ni ni-user-run text-white'></i>
                    </div>
                    <h5 className='info-title'>Habit Focused </h5>
                    <p className='description opacity-8'>
                      Our focus is on simple behavioral training. Just one day at a time. By following this one simple system
                       it will have drastic impact on every area of your life. You'll feel stronger and better.
                       
                    </p>
                  </div>
                </Col>
                <Col md='6'>
                  <div className='info info-hover-success'>
                    <div className='icon icon-shape bg-success shadow rounded-circle text-primary'>
                      <i className='fas fa-chart-line text-white'></i>
                    </div>
                    <h5 className='info-title'>Steadfast Consistency</h5>
                    <p className='description opacity-8'>
                      "Commitment is doing the thing you 
                      said you were going to do long after 
                      the mood you said it in has left you."
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature4;
