import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

function WorkoutLevels() {
  return (
    <>
      <section className="blogs-2">
        <Container fluid>
          <Row className="mb-md-5">
            <Col className="mx-auto" md="7">
              <h3 className="display-3 text-center text-uppercase">Home &amp; Gym Workouts</h3>
               <h4 className="lead text-center">
                <b> Pick the program that best fits your style.</b> 
               </h4>
                <p className="lead text-center"> 
                As an Elite Coach I will help lighten your load by offloading all the <b>researching 
                and planning required</b> to design a <b>safe and effective workout program</b> that fits 
                your current lifestyle. 
                </p>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/faces/507817844936.JPG") + ")",
                  }}
                ></div>
                <a href="https://app.myfitnesscoach.io/sign-up/9542894517/175/prod_JSCfb0Dp25RX5P/price_1IpIGLPv0i0Y0u9y9JhYXfld">
                  <CardBody>
                    <div className="content-bottom">
                      <h4 className="card-category text-white">
                        <span><i className="fas fa-at"></i></span><b>HOME</b>
                      </h4>
                      <h5 className="card-category text-white opacity-8">3 Days a week</h5>
                      <h5 className="card-category text-white opacity-8">Bands &amp; Light Dumbbells</h5>
                      <p className="text-white card-category">Mix of Total Body and steady state cardio.</p>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/faces/mb4.jpg") + ")",
                  }}
                ></div>
                <a href="https://app.myfitnesscoach.io/sign-up/9542894517/176/prod_JSCfb0Dp25RX5P/price_1IpIGLPv0i0Y0u9y9JhYXfld">
                  <CardBody>
                    <div className="content-bottom">
                      <h4 className="card-category text-white">
                        <b>LIFESTYLE</b> 
                      </h4>
                      <h5 className="card-category text-white opacity-8">3 Days a week</h5>
                      <h5 className="card-category text-white opacity-8">2 Optional Conditioning Days</h5>
                      <p className="text-white">Mix of Total Body and Steady State Cardio.</p>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/faces/battleropes.jpg") + ")",
                  }}
                ></div>
                <a href="https://app.myfitnesscoach.io/sign-up/9542894517/177/prod_JSCfb0Dp25RX5P/price_1IpIGLPv0i0Y0u9y9JhYXfld">
                  <CardBody>
                    <div className="content-bottom">
                      <h4 className="card-category text-white">
                        <b>LIFESTYLE &amp; HIIT</b>
                      </h4>
                      <h5 className="card-category text-white opacity-8">3 Days a week</h5>
                      <h5 className="card-category text-white opacity-8">2 Optional HIIT Conditioning Days</h5>
                      <p className="text-white">Mix of Total Body and HIIT workouts.</p>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
            <Col lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/faces/lifestyleplus.jpg") + ")",
                  }}
                ></div>
                <a href="https://app.myfitnesscoach.io/sign-up/9542894517/178/prod_JSCfb0Dp25RX5P/price_1IpIGLPv0i0Y0u9y9JhYXfld">
                  <CardBody>
                    <div className="content-bottom">
                      <h4 className="card-category text-white">
                        <b>LIFESTYLE <span><i className="fas fa-plus"></i></span></b>
                      </h4>
                      <h5 className="card-category text-white opacity-8">5 Days a week</h5>
                      <h5 className="card-category text-white opacity-8">1 Optional Recovery Day</h5>
                      <p className="text-white">Mix of Strength, Hypertrophy, 
                      Bodybuilding, Total Body, Upper &amp; Lower Body Splits.</p>
                    </div>
                  </CardBody>
                </a>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default WorkoutLevels;
