import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

function Stanley() {
  return (
    <>
      <section className="blogs-3">
        <Container>
          <Row>
            <Col className="mx-auto" lg="10" md="8">
              <h2 className="title mb-5 text-uppercase">Client Results</h2>
              <Card className="card-blog card-plain blog-horizontal mb-5">
                <Row>
                  <Col lg="4">
                    <div className="card-image shadow">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require("assets/img/faces/stanley.jpg")}
                        ></img>
                      </a>
                    </div>
                  </Col>
                  <Col lg="8">
                    <CardBody>
                      <CardTitle tag="h3">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          LOST 33lbs!
                        </a>
                      </CardTitle>
                      <blockquote className="blockquote">
                        <p className="mb-0">
                          I've lost 33lbs and gained 20lbs of muscle. On my own I did not have any 
                          results close to what I've been able to achieve. I was always getting injured 
                          and not being consistent as I wanted to be. Now, I feel better than I ever have!

                          I used to have a short term mindset when it came to getting in shape. 
                          I realized that the fitness experts I wanted to look like didn't get that 
                          way in 30, 60, or even 90 days. They did it over the course of many years of consistent
                          training. 
                          </p>
                        <footer className="blockquote-footer">
                          Stanley V. - <cite title="Source Title">Current Client</cite>
                        </footer>
                      </blockquote>
                      
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Stanley;
