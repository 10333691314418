import React from 'react';

// reactstrap components
import { Card, Container, Badge, Row, Col } from 'reactstrap';

// core components

function Feature8() {
  return (
    <>
      <div className='features-6'>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto text-center' md='8'>
              <h2 className='display-3 text-uppercase'>Custom Web App</h2>
              <h4 className='lead lead-title'>
                We built our web app from the ground up so you can 
                have your workouts delivered to the palm of your 
                hand. Use your laptop,
                desktop, tablet, or your mobile phone.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md='4'>
              <div className='info info-hover-warning'>
                <div className='icon icon-shape bg-warning shadow rounded-circle text-primary'>
                  <i className='far fa-check-circle text-white'></i>
                </div>
                <h5 className='info-title'>Easy Checkoff</h5>
                <p className='description opacity-8'>
                  Easily checkoff all your exercises with the touch of a button.
                </p>
              </div>
              <div className='info info-hover-info'>
                <div className='icon icon-shape bg-info shadow rounded-circle text-primary'>
                  <i className='ni ni-chart-bar-32 text-white'></i>
                </div>
                <h5 className='info-title'>Track Progress</h5>
                <p className='description opacity-8'>
                  You can't improve what you can't measure. Track your progress
                  each day, week and month.
                </p>
              </div>
            </Col>
            <Col md='4'>
              <div className='phone-container'>
                <img
                  alt='...'
                  src={require('assets/img/mfciphoneapp.png')}
                ></img>
              </div>
            </Col>
            <Col md='4'>
              <div className='info info-hover-danger'>
                <div className='icon icon-shape bg-danger shadow rounded-circle text-primary'>
                  <i className='far fa-play-circle text-white'></i>
                </div>
                <h5 className='info-title'>Video Instruction</h5>
                <p className='description opacity-8'>
                  Each exercise has video and easy to follow instructions.
                </p>
              </div>
              <div className='info info-hover-success'>
                <div className='icon icon-shape bg-success shadow rounded-circle text-primary'>
                  <i className='fas fa-chart-line text-white'></i>
                </div>
                <h5 className='info-title'>Real Results</h5>
                <p className='description opacity-8'>
                  Random workouts will not get you the body you want.
                  A structured program designed for linear growth 
                  along with the most effective exercises 
                  will give you results you want. 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature8;
