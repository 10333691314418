import React from "react";

// reactstrap components
import { Button, Row, Col } from "reactstrap";

// Core Components

function MFCHeader() {
  return (
    <>
      <div className="page-header page-header-small header-filter">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/faces/mfccouplerun.jpg") + ")",
          }}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="mx-auto text-center" md="6">
              <h1 className="title text-white text-uppercase header-title">
              Stop Chasing 30 Day Fixes. 
              <br></br>
              Start Playing The Long Game.
              </h1>
              <p className="header-top-spacing"><b>1:1 Remote Fitness Coaching</b></p>

              <div className="buttons header-top-spacing">
                  <Button
                    color="primary"
                    href="/#section-workoutlevels"
                    
                  >
                    Get Started
                  </Button>
                </div>
                
                  <p className="header-top-spacing">As low as $2.48 a day</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default MFCHeader;
