import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle } from "reactstrap";

// Core Components

function FullBackGroundCardS1() {
  return (
    <>
      <Card className="card-blog card-background" data-animation="zooming">
        <div
          className="full-background"
          style={{
            backgroundImage:
              "url(" + require("assets/img/faces/stairscoach.jpg") + ")",
          }}
        ></div>
        <a href="#pablo" onClick={(e) => e.preventDefault()}>
          <CardBody>
            <div className="content-bottom">
            <CardTitle tag="h5">Why a Coach?</CardTitle>
              <h6 className="card-category text-white opacity-8">
                Elite athletes, Top CEO's, and Successful people hire 
                coaches to keep them at the top of their game.
                
              </h6>
              
            </div>
          </CardBody>
        </a>
      </Card>
    </>
  );
}

export default FullBackGroundCardS1;
