import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

function FAQ() {
  const [openedCollapse, setOpenedCollapse] = React.useState("collapse-1");
  return (
    <>
      <div className="accordion-1">
        <Container>
          <Row>
            <Col className="mx-auto text-center" md="8">
              <h3 className="display-3 text-center text-uppercase">Frequently asked question</h3>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="12">
              <div className="accordion" id="accordionExample">
                <Card>
                  <CardHeader id="headingOne">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-1"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-1" ? "" : "collapse-1"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        How does remote online training work?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-1"}>
                    <CardBody className="opacity-8">
                    My team and I are real people and are on-call to support you.  
                    We will send you text reminders, weekly or biweekly check-ins and 
                    help when you need it via text message. I will build a new training plan for you each week.
                    Each day you will see your workout for that day. Together we'll just focus on one workout at a time.
                    You will be able to access your workouts through our custom web app. You can use 
                    your phone, tablet, or laptop to access your workouts.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingOne">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-11"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-11" ? "" : "collapse-11"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        Can I use my HSA dollars or wellness credit to cover MFC Training?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-11"}>
                    <CardBody className="opacity-8">
                    Yes! You can use your HSA accounts to pay for your training.
                    To be eligible your primary care physician is required to prescribe 
                    exercise for this to be eligible. 
                    <b> Wellness credit</b> - most employers will let you use personal training towards your wellness credit each month.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingTwo">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-2"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-2" ? "" : "collapse-2"
                          )
                        }
                        className="w-100 text-primary text-left collapsed"
                        color="link"
                        type="button"
                      >
                        What program do you recommend I start with?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-2"}>
                    <CardBody className="opacity-8">
                    We will build your workouts based on what best fits your lifestyle. 
                    For busy professionals like yourself we recommend our <b>Lifestyle </b> 
                     program (3 Total Body workouts per week MWF, and 2 optional conditioning 
                    days T,TH). For optional days you can pick either steady state cardio (running, 
                    biking, rowing etc...) or HIIT (High Intensity Workouts).
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingThree">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-3"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-3" ? "" : "collapse-3"
                          )
                        }
                        className="w-100 text-primary text-left collapsed"
                        color="link"
                        type="button"
                      >
                        What is the <b>LIFESTYLE <span><i className="fas fa-plus"></i></span></b> program?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-3"}>
                    <CardBody className="opacity-8">
                    Our lifestyle plus program is designed to be 5 days a week. 
                    It is a mix of Strength, Hypertrophy, 
                      Bodybuilding, Total Body, Upper &amp; Lower Body Splits etc...
                      We recommend this program if you are already in the habit of training 
                      4-5 days a week.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingFour">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-4"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-4" ? "" : "collapse-4"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        How long are the workouts?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-4"}>
                    <CardBody className="opacity-8">
                    Gym workouts are designed to be 45-60 mins long. However,
                       they can be adjusted to what fits in your schedule. Our 
                       at Home workouts are designed to be around 30 mins long. 
                       As you get comfortable with the exercises you will be able to 
                       get through the workouts at a good pace.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingFifth">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-5"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-5" ? "" : "collapse-5"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        What equipment do I need?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-5"}>
                    <CardBody className="opacity-8">
                      For our <b>LIFESTYLE</b> or <b>LIFESTYLE <span><i className="fas fa-plus"></i></span></b> program any standard commercial 
                      or well equiped home gym will work. The <span><i className="fas fa-at"></i></span><b>HOME</b> workout program can be done with your 
                      own bodyweight, however, light dumbbells and/or resistance bands and a stability ball 
                      are recommended. 
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingOne">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-6"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-6" ? "" : "collapse-6"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        Can I switch programs at any time?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-6"}>
                    <CardBody className="opacity-8">
                    Yes absolutely! As a member of MFC, you can switch programs at 
                    any time; however, we recommend you pick what will help you stay consistent. 
                    We build our programs to help keep it simple for you. Consistency is the most 
                    important factor when staying healthy and fit.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingOne">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-7"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-7" ? "" : "collapse-7"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        What if I go on vacation?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-7"}>
                    <CardBody className="opacity-8">
                    If you have limited gym equipement due to traveling we will 
                    help tailor the workouts to help you. Most of our clients will 
                    just use bodyweight or switch to a similar exercise that is the same 
                    movement pattern. For example, switching out barbell bench press with 
                    dumbbell bench press or even doing push ups. 
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingOne">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-8"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-8" ? "" : "collapse-8"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        What is My Fitness Coach's cancellation policy?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-8"}>
                    <CardBody className="opacity-8">
                    We want you to be completely satisfied with your program. 
                    You can try it risk-free for the first month, if you decide 
                    to cancel we will give you a full refund. After the 
                    first month, you can easily cancel with the push of a button 
                    on your account page. If you are ever unsatisfied with our service, you 
                    can email support[at]myfitnesscoach.io and we'll be more than happy to make it right. 
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingOne">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-9"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-9" ? "" : "collapse-9"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        What if I have an injury or special neeeds?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-9"}>
                    <CardBody className="opacity-8">
                    Once your medical professional has cleared you for an exercise 
                    program then working with a fitness coach can be highly beneficial 
                    for preventing injuries or managing many common conditions. 
                    We recommend emailing us at support[at]myfitnesscoach.io and we will 
                    help guide you on what program to join or how we can help create a specific 
                    program just for you. 
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader id="headingOne">
                    <h5 className="mb-0">
                      <Button
                        aria-expanded={openedCollapse === "collapse-10"}
                        onClick={() =>
                          setOpenedCollapse(
                            openedCollapse === "collapse-10" ? "" : "collapse-10"
                          )
                        }
                        className="w-100 text-primary text-left"
                        color="link"
                        type="button"
                      >
                        How much does it cost?{" "}
                        <i className="ni ni-bold-down float-right pt-1"></i>
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={openedCollapse === "collapse-10"}>
                    <CardBody className="opacity-8">
                    My normal hourly rate is $100 per workout (about $1200 a month). However, because of 
                    technology I am able to give you the same type of program design for 
                    a fraction of the cost. At only $5 a day you can get the same kind of 
                    guidance. That is half the cost of buying lunch each day.
                    We let you join with a friend as well. So for each of you 
                    it would be about $2.48 a day. Once you are a member we will reach out and see if 
                    there's anyone you'd like to join with you and will send them an invite.
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FAQ;
