import React from 'react';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Container,
  Row,
  Col,
} from 'reactstrap';
import RosasProfileCard from "components/mfc-training/mfc-cards/RosasProfileCard.js";

// Core Components

function Coaches2() {
  return (
    <>
      <div className='coaches'>
      <Container>
      <Row className="coaches-center">
          <Col className="pl-0" lg="4" md="6">
                <RosasProfileCard />
              </Col>
              <Col className="coach-description" lg="7" xs="12">
                <h1 className="display-3 text-uppercase">
                  Led by a World Class
                  <span className="text-primary text-uppercase"><b>Coach.</b></span>
                </h1>
                <p className="first-bio">Hi I'm Mike Rosas. I have been 
                coaching elite athletes, celebrities and busy professionals 
                at the highest levels for the past 20 years. 
                My specialization is in Strength and Conditioning at the Collegiate 
                level. I've done extensive continuing education CHEK, NSCA, NASM and 
                many others. I love working with all different levels of 
                individuals looking to improve performance and vitality.</p>

                <h4>Professional Clients:</h4>
                <ul className="card-list-elements">
                    <li>Claude Lemieux-NHL</li>
                    <li>Josh Burkman-UFC</li>
                    <li>Deron Williams-NBA</li>
                    <li>Goran Dragic-NBA</li>
                    <li>Evan Tanner-UFC</li>
                </ul>            
              </Col>
          </Row>
          </Container>
      </div>
    </>
  );
}

export default Coaches2;
